<template>
<!-- BEGIN HIGHLIGHT -->
<div class="content">
    <div class="container">
        <div class="row">
            <div class="main col-sm-8">
                <div class="center">
                    <h2 class="section-highlight" data-animation-direction="from-left"
                    data-animation-delay="50">
                        {{$t("pages.about.company.title")}}
                    </h2>
                    <p data-animation-direction="from-left" data-animation-delay="650">
                        {{$t("pages.about.company.description")}}</p>
                    <br />

                    <p class="darker-text" data-animation-direction="from-left" data-animation-delay="250">
                        {{$t("pages.about.vision.title")}}
                    </p>
                    <p data-animation-direction="from-left" data-animation-delay="650">
                        {{$t("pages.about.vision.description")}}
                    </p>
                    <br />

                    <p class="darker-text" data-animation-direction="from-left" data-animation-delay="250">
                        {{$t("pages.about.mision.title")}}
                    </p>
                    <p data-animation-direction="from-left" data-animation-delay="650">
                        {{$t("pages.about.mision.description")}}
                    </p>
                </div>
            </div>

            <div class="col-sm-4 text-center">
                <div class="mt-5">
                    <h3>Ingrid Cinco</h3>
                    <!-- <img v-if="info" class="logo m-1" :src="info.logoClean" alt="logo_web" /> -->
                    <!-- <h3 v-else> </h3> -->
                </div>
                
                <div class="mt-5">
                    <p class="darker-text text-center" data-animation-direction="from-left" data-animation-delay="250">
                        {{$t("pages.about.values.title")}}
                    </p>
                    <ul data-animation-direction="from-left" data-animation-delay="650">
                        <li> {{$t("pages.about.values.example_one.description")}} </li>
                        <li>{{$t("pages.about.values.example_two.description")}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- END HIGHLIGHT -->

</template>


<script>
import { mapGetters } from 'vuex';
import LocaleSwitcher from "@/components/layout/LocaleSwitcher.vue";
export default {
  components:{
    LocaleSwitcher
  },
    computed:{
        ...mapGetters({
            info:'_getInfoCompany'
        }),
    },
}
</script>

<style scoped>
.logo{
    width: 40% !important;
}

.mt-5{
    margin-top: 40px;
}
</style>